import React, { useEffect, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { TrustCenterState } from '../center/index.zh'
import { CommonContentList } from 'components/Trust/Card/CommonCard'
import SectionContent from 'components/SectionContent'
import Layout from 'layouts/zh'
import * as styles from './index.module.less'

const Compliance: React.FC = (props) => {
  const { trustCenterYaml } = useStaticQuery(
    graphql`
      query {
        trustCenterYaml {
          compliance {
            title
            description
            key
            satitle
          }
          banner_bg_pc
          banner_bg_mb
          compliance_banner_info {
            content
            title
          }
          compliance_tab_list {
            is_active
            link
            name
          }
          bottom_card_title
          bottom_card_desc
          compliance_content_info {
            desc
            title
          }
          compliance_content_list {
            title
            content {
              content
            }
            icon
          }
        }
      }
    `,
  )

  const { compliance, compliance_tab_list, compliance_banner_info, compliance_content_info, compliance_content_list } =
    trustCenterYaml

  return (
    <Layout {...props}>
      <TrustCenterState
        trustCenterYaml={trustCenterYaml}
        TDK={compliance}
        bannerInfo={compliance_banner_info}
        tabList={compliance_tab_list}
      >
        <>
          <SectionContent
            className={styles.trustCompliance}
            title={compliance_content_info.title}
            descText={compliance_content_info.desc}
          >
            <CommonContentList data={compliance_content_list} smallIcon={compliance_content_info?.small_icon} />
          </SectionContent>
        </>
      </TrustCenterState>
    </Layout>
  )
}

export default Compliance
